import React, { useEffect, useState } from "react";
import { AiOutlineZoomIn } from "react-icons/ai";
import { PopupboxContainer, PopupboxManager } from "react-popupbox";
import "react-popupbox/dist/react-popupbox.css";
import { Slide } from "react-awesome-reveal";

import "./Portfolio.css";

const Portfolio = () => {
  const [portfolioImgs, setPortfolioImgs] = useState([]);

  useEffect(() => {
    const fetchImages = async () => {
      const resp = await fetch(
        "https://sushant-portfolio-2ced2-default-rtdb.europe-west1.firebasedatabase.app/images.json"
      );
      const data = await resp.json();
      setPortfolioImgs(data.portfolio);
    };
    fetchImages();
  }, []);

  const openPopup = ({ title, image, alt, link, desc, environment }) => {
    const content = (
      <>
        <img className="portfolio-image-popupbox" src={image} alt={alt} />
        <p className="mt-5">{desc}</p>
        <p>
          <b>Environment : </b> {environment}
        </p>
        {link && (
          <>
            <b>Link : </b>
            <a className="hyper-link" href={link} target="_blank">
              {link}
            </a>
          </>
        )}
      </>
    );
    PopupboxManager.open({
      content,
      config: {
        titleBar: {
          enable: true,
          text: title,
        },
        fadeIn: true,
        fadeInSpeed: 500,
        scroll: true,
      },
    });
  };

  return (
    <div id="portfolio" className="portfolio-wrapper">
      <div className="container">
        {/* <AttentionSeeker effect="heartBeat" duration="1500"> */}
        <h1 className="text-uppercase text-center py-5 title">Portfolio</h1>
        {/* </AttentionSeeker> */}
        <Slide>
          <div className="image-box-wrapper row justify-content-center">
            {portfolioImgs.instagram && (
              <div
                className="portfolio-image-box"
                onClick={() =>
                  openPopup({
                    title: "Instagram Clone Project",
                    image: portfolioImgs.instagram,
                    alt: "Instagram Clone",
                    link: "https://insta-sushant.herokuapp.com/",
                    desc: "Instagram Clone is Mini Social Networking site developed using MERN stack technologies. It has interactive interface where user can do social media activities which actual Instagram has.",
                    environment:
                      "React.js, Materialize CSS, Node.js, Express.js, MongoDB, Mongoose, Git, GitHub, Firebase Hosting, Heroku, Cloudinary",
                  })
                }
              >
                <img
                  className="portfolio-image"
                  src={portfolioImgs.instagram}
                  alt="Instagram Clone"
                />
                <div className="overflow"></div>
                <AiOutlineZoomIn className="portfolio-icon" />
              </div>
            )}

            {portfolioImgs.myPlaces && (
              <div
                className="portfolio-image-box"
                onClick={() =>
                  openPopup({
                    title: "My Places Social App",
                    image: portfolioImgs.myPlaces,
                    alt: "My Places",
                    link: "https://my-places-mernapp.web.app/s",
                    desc: "Developed using MERN stack technologies. My Places is mini social app where users can add places they visited with map location and other users will be able to see places added by different users.",
                    environment:
                      "React.js, Node.js, Express.js, MongoDB, Mongoose, Git, GitHub, Firebase Hosting, Heroku, Cloudinary",
                  })
                }
              >
                <img
                  className="portfolio-image"
                  src={portfolioImgs.myPlaces}
                  alt="My Places"
                />
                <div className="overflow"></div>
                <AiOutlineZoomIn className="portfolio-icon" />
              </div>
            )}

            {portfolioImgs.emart && (
              <div
                className="portfolio-image-box"
                onClick={() =>
                  openPopup({
                    title: "e-Mart Solutions : Online Shopping Website",
                    image: portfolioImgs.emart,
                    alt: "E-Mart Solutions",
                    desc: "E-Mart Solution is a B2C e-Commerce System which has almost all features which any shopping site has. It has interactive product catalogue, online purchasing facility.",
                    environment:
                      "React.js, Bootstrap, Spring Boot, Spring Data JPA, MySQL, Spring Security, Node.js, Git, GitHub, Stripe, AWS RDS, AWS Elastic Beanstalk, Heroku, Cloudinary",
                  })
                }
              >
                <img
                  className="portfolio-image"
                  src={portfolioImgs.emart}
                  alt="e-Commerce Website"
                />
                <div className="overflow"></div>
                <AiOutlineZoomIn className="portfolio-icon" />
              </div>
            )}
          </div>
        </Slide>
      </div>
      <PopupboxContainer />
    </div>
  );
};

export default Portfolio;

import React, { useState } from "react";
import { BrowserRouter } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar/Navbar";
import Header from "./components/Header/Header";
import AboutMe from "./components/AboutMe/AboutMe";
import Skills from "./components/Skills/Skills";
import Journey from "./components/Journey/Journey";
import Portfolio from "./components/Portfolio/Portfolio";

import ContactMe from "./components/ContactMe/ContactMe";
import Footer from "./components/Footer/Footer";

const App = () => {
  const [isDarkMode, setDarkMode] = useState(true);
  const toggleDarkMode = () => setDarkMode(isDarkMode ? false : true);

  return (
    <div className="App" data-theme={isDarkMode ? "dark" : "light"}>
      <BrowserRouter>
        <Navbar darkMode={isDarkMode} onModeChange={toggleDarkMode} />
        {/* 
        <Particles
          id="tsparticles"
          options={{
            fpsLimit: 250,
            particles: {
              links: {
                color: isDarkMode ? "#ffffff" : "#3e1e1e",
                distance: 150,
                enable: true,
                opacity: 0.5,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                direction: "none",
                enable: true,
                outMode: "bounce",
                random: false,
                speed: 4,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  value_area: 1300,
                },
                value: 80,
              },
              opacity: {
                value: 0.5,
              },
              shape: {
                type: "circle",
                stroke: {
                  width: 3,
                  color: "#f9ab00",
                },
              },
              size: {
                random: true,
                value: 5,
              },
            },
            detectRetina: true,
          }}
          style={{ maxHeight: "100%" }}
        /> */}

        <Header darkMode={isDarkMode} />
        <AboutMe>{/* <ParticlesBg type="random" bg={true} /> */}</AboutMe>
        <Skills />
        <Journey />
        <Portfolio />
        {/* <Gallery /> */}
        <ContactMe darkMode={isDarkMode} />
        <Footer />
      </BrowserRouter>
    </div>
  );
};

export default App;

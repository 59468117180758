import React from "react";
import { Link } from "react-scroll";
import { AiOutlineMail } from "react-icons/ai";
import { CgPhone } from "react-icons/cg";
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon,
  LinkedinShareButton,
  LinkedinIcon,
} from "react-share";
import SocialMediaButtons from "react-social-media-buttons";
import { AttentionSeeker } from "react-awesome-reveal";

import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6 mb-4">
            <div className="d-flex mb-3">
              <p className="mt-3">Connect me on</p>
              <SocialMediaButtons
                links={[
                  "https://www.linkedin.com/in/thombaresushant/",
                  "https://twitter.com/Sushant_SVT",
                ]}
                iconStyle={{
                  color: "#ffffff",
                }}
                buttonStyle={{
                  margin: "-2px",
                }}
                openNewTab={true}
                margin="2px"
              />
            </div>
            - Please feel free to drop a message <br />
            so that we can start a conversation.
            {/* Site's Old Version : <br /> */}
            {/* Currently unavailable */}
            <div className="d-flex">
              {/* <a
                href="https://sushant-portfolio-v1.herokuapp.com/"
                target="_blank"
                style={{ textTransform: "none" }}
              >
                - version 1
              </a> */}
            </div>
            <div className="d-flex">
              {/* <a
                href="https://sushant-portfolio-v2.herokuapp.com/"
                target="_blank"
                style={{ textTransform: "none" }}
              >
                - version 3
              </a> */}
            </div>
          </div>
          <div className="col-lg-3 col-md-2 col-sm-6 mb-4">
            <div className="row">
              <div className="col">
                <Link to="home" offset={-110} className="footer-nav">
                  Home
                </Link>
                <br />
                <Link to="about" offset={-80} className="footer-nav">
                  About me
                </Link>
                <br />
                <Link to="services" offset={-80} className="footer-nav">
                  skills
                </Link>
              </div>
              <div className="col">
                <Link to="journey" offset={-80} className="footer-nav">
                  journey
                </Link>
                <br />
                <Link to="portfolio" offset={-50} className="footer-nav">
                  Portfolio
                </Link>
                <br />
                <Link to="contact" offset={-80} className="footer-nav">
                  Contacts
                </Link>
              </div>
            </div>
            <AttentionSeeker effect="heartBeat" duration="7000">
              <p className="mt-4 text-center">Made with ❤ by Sushant</p>
            </AttentionSeeker>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-6 align-items-center">
            <div className="d-flex justify-content-center">
              Share site on
              <LinkedinShareButton
                url={"https://sushant-t.herokuapp.com/"}
                quote="Personal Website of Sushant Thombare"
                hashtag="#fullStackDeveloper"
              >
                <LinkedinIcon className="mx-2" size={36} />
              </LinkedinShareButton>
              <FacebookShareButton
                url={"https://sushant-t.herokuapp.com/"}
                quote="Personal Website of Sushant Thombare"
                hashtag="#fullStackDeveloper"
              >
                <FacebookIcon className="mx-2" size={36} />
              </FacebookShareButton>
              <TwitterShareButton
                url={"https://sushant-t.herokuapp.com/"}
                quote="Personal Website of Sushant Thombare"
                hashtag="#fullStackDeveloper"
              >
                <TwitterIcon className="mx-2" size={36} />
              </TwitterShareButton>
              <RedditShareButton
                url={"https://sushant-t.herokuapp.com/"}
                quote="Personal Website of Sushant Thombare"
                hashtag="#fullStackDeveloper"
              >
                <RedditIcon className="mx-2" size={36} />
              </RedditShareButton>
            </div>
            <p className="pt-4 text-center">
              All Rights Reserved &copy; &nbsp;
              {new Date().getFullYear()}&nbsp;Sushant Thombare
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

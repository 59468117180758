import React from "react";
import { Link } from "react-scroll";
import { othersImages } from "../../data/Images";
import { FaBars } from "react-icons/fa";
import DarkModeToggle from "react-dark-mode-toggle";
import { Slide } from "react-awesome-reveal";

import "./Navbar.css";

const Navbar = ({ darkMode, onModeChange }) => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg fixed-top">
        <div className="container">
          <Link
            className="navbar-brand my-2"
            to="home"
            data-bs-toggle="collapse"
            data-bs-target=".navbar-collapse.show"
          >
            <img src={othersImages[0].url} alt="My Logo" className="logo-img" />
            <p className="my-logo d-inline mx-2">SUSHANT-THOMBARE</p>
          </Link>

          <span title={"Switch to " + (darkMode ? "Light" : "Dark") + " Mode"}>
            <DarkModeToggle
              onChange={onModeChange}
              checked={darkMode}
              speed={3}
              size={40}
              className="mode-toggle-btn"
            />
          </span>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <FaBars className={darkMode ? "text-white" : "text-dark"} />
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto">
              <Slide direction="right" duration="1000">
                <li className="nav-item active">
                  <Link
                    className="nav-link"
                    offset={-110}
                    smooth={false}
                    to="home"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-80}
                    smooth={false}
                    to="about"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    about
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-80}
                    smooth={false}
                    to="services"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    skills
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-50}
                    smooth={false}
                    to="journey"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    expedition
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-50}
                    smooth={false}
                    to="portfolio"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    portfolio
                  </Link>
                </li>
                {/* <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-80}
                    smooth={false}
                    to="gallery"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    gallery
                  </Link>
                </li> */}
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    offset={-50}
                    smooth={false}
                    to="contact"
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                  >
                    Contact
                  </Link>
                </li>
              </Slide>
            </ul>
          </div>
          {/* <button
            type="button"
            class={`btn ${darkMode ? "btn-outline-light" : "btn-outline-dark"}`}
            onClick={onModeChange}
          >
            {darkMode ? (
              <>
                Light Mode <IoInvertModeSharp />
              </>
            ) : (
              <>
                Dark Mode <IoInvertModeSharp />
              </>
            )}
          </button> */}
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

export const galleryImages = [
  {
    name: "me2",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626172/sushant-portfolio/me2_ippmpy.jpg",
  },
  {
    name: "me3",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1627395707/sushant-portfolio/me3_xdp707.jpg",
  },
  {
    name: "me12",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1628272456/sushant-portfolio/me12_xcekzq.jpg",
  },
  // {
  //   name: "me1",
  //   url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626170/sushant-portfolio/me1_sof2xb.jpg",
  // },
  {
    name: "me4",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626168/sushant-portfolio/me4_szkpum.jpg",
  },
  {
    name: "me5",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626168/sushant-portfolio/me5_wn1f4d.jpg",
  },
  {
    name: "me6",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626170/sushant-portfolio/me6_sizrjg.jpg",
  },
  {
    name: "me7",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1627395709/sushant-portfolio/me7_u5e8ue.jpg",
  },
  {
    name: "me8",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1627395708/sushant-portfolio/me8_kgdplh.jpg",
  },
  // {
  //   name: "me9",
  //   url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1627395709/sushant-portfolio/me9_wohm4p.jpg",
  // },
  {
    name: "me10",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1627395711/sushant-portfolio/me10_uys318.jpg",
  },
];

export const othersImages = [
  {
    name: "myLogo",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626952/sushant-portfolio/mylogo_hylvr0.png",
  },
  {
    name: "sushant",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626962/sushant-portfolio/sushant_nyybax.jpg",
  },
  {
    name: "headerWall",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1628270833/sushant-portfolio/header-wall_mmqb56.jpg",
  },
  {
    name: "galleryWall",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1628270872/sushant-portfolio/gallery-wall_fobzr8.jpg",
  },
];

export const portfolioImages = [
  {
    name: "emart",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626157/sushant-portfolio/emart_agmnkp.jpg",
  },
  {
    name: "instagram",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1626626156/sushant-portfolio/insta-clone_z18bbm.png",
  },
  {
    name: "my-places",
    url: "https://res.cloudinary.com/sushant-thombare/image/upload/v1641128142/sushant-portfolio/my-places_s8joxq.jpg",
  },
];

export default {
  galleryImages,
  othersImages,
  portfolioImages,
};

import React, { useEffect, useState } from "react";
import { Slide, Fade, AttentionSeeker } from "react-awesome-reveal";
import { othersImages } from "../../data/Images";
import aboutImgBg from "../../images/about-bg.svg";

import "./AboutMe.css";

const AboutMe = () => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const fetchDocs = async () => {
      const resp = await fetch(
        "https://sushant-portfolio-2ced2-default-rtdb.europe-west1.firebasedatabase.app/links.json"
      );
      const data = await resp.json();
      setDocs(data);
    };
    fetchDocs();
  }, []);

  return (
    <>
      <div id="about" className="container-fluid about py-5">
        <div className="row">
          <div className="col-lg-5 col-xm-12">
            <div className="about__photo-wrap mb-5">
              <Slide duration="1700">
                <img className="about__profile-img" src={othersImages[1].url} />
              </Slide>
            </div>
          </div>

          <div className="col-lg-6 col-xm-12 about-div">
            <AttentionSeeker effect="jello">
              <h1 className="about__heading">about me</h1>
            </AttentionSeeker>
            <img class="about-bg" src={aboutImgBg} alt=""></img>
            <Fade cascade className="about__content" duration="500">
              <p>
                Hello! I'm <strong className="my-name">Sushant</strong>
              </p>
              <p>
                A software enthusiast & certified Developer by{" "}
                <strong>Salesforce</strong>, currently working at{" "}
                <strong>Infibeam Avenues Ltd.</strong> in Payment Division, an{" "}
                <strong>Engineer Graduate</strong> in{" "}
                <strong>Information Technology</strong> & completed{" "}
                <strong>Diploma in Advanced Computing</strong> from{" "}
                <strong>C-DAC ACTS Pune</strong>.
              </p>
              <p>
                First and foremost, <strong>I love writing code</strong>. Ever
                since I was a little kid, <strong>Software Development</strong>{" "}
                has been my passion. From writing my first program in{" "}
                <strong>Java</strong> and manipulating it to produce a desired
                output, I have been obsessed with the idea of using software to
                solve practical problems and passionate about implementing and
                launching new <strong>full stack projects</strong>.
              </p>
              <p>
                <strong>Software Engineering</strong> is a never-ending puzzle
                that I am passionately engaged in solving. So, now in industry,
                this passion remains with me.
              </p>
              <p>
                <strong>Always in learning mode</strong>. This is what keeps me
                up at night,{" "}
                <strong>
                  a never ending thirst to create beautiful, powerful things
                </strong>{" "}
                and share them with the world.{" "}
              </p>
              <p>
                There’s certainly <strong>yet more to learn</strong>,{" "}
                <strong>yet more problems to solve</strong>, and{" "}
                <strong>yet more to build</strong>, And for this, I am{" "}
                <strong>GRATEFUL</strong>.
              </p>
            </Fade>
            <div className="text-center about-btns">
              {docs.resumeView && (
                <a
                  href={docs.resumeView}
                  target="_blank"
                  className="btn-outline"
                  style={{ cursor: "pointer" }}
                >
                  View Resume
                </a>
              )}
              {docs.resumeDownload && (
                <a href={docs.resumeDownload} className="btn-outline">
                  Download Resume
                </a>
              )}
              {docs.cdac && (
                <a href={docs.cdac} target="_blank" className="btn-outline">
                  CDAC Accredition
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;

import React from "react";
import { AttentionSeeker, Slide } from "react-awesome-reveal";

import "./Journey.css";

const Journey = () => {
  return (
    <div id="journey" className="journey">
      <div className="d-flex justify-content-center my-5">
        <AttentionSeeker effect="heartBeat" duration="1500">
          <h1 className="title">expedition</h1>
        </AttentionSeeker>
      </div>

      <div className="container journey-wrapper">
        <Slide cascade direction="right" duration="800">
          <div className="timeline-block timeline-block-right">
            <div className="marker"> </div>
            <div className="timeline-content">
              <h3>2021-Present </h3>
              <p>
                <b>Software Programmer at Infibeam Avenues Ltd. </b>
                <br />
                Working on Backend for Banking Integrations for CCAvenue Payment
                Gateway.
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>CDAC, 2020-21 </h3>
              <p>
                <b>Diploma in Advance Computing from C-DAC ACTS Pune</b> <br />
                Learnt technologies: Java, Spring Boot, React.js, Data
                Structure, OS, JavaScript, SQL, etc.
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2019, PD1</h3>
              <p>
                <b>Salesforce Certified Platform Developer I</b>
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-left">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>B.E., 2015-19</h3>
              <p>
                <b>Bachelor of Engineering in Information Technology</b> <br />
                Pursued graduation from RTM Nagpur University
              </p>
            </div>
          </div>
          <div className="timeline-block timeline-block-right">
            <div className="marker"></div>
            <div className="timeline-content">
              <h3>2015, HSC</h3>
              <p>
                <b>Higher Secondary Certificate from MSBSHSE, Pune</b>
              </p>
            </div>
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default Journey;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { BiHappy } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import emailjs from "emailjs-com";
import { AttentionSeeker, Zoom, Slide } from "react-awesome-reveal";
import swal from "sweetalert";

import LoadingSpinner from "../UI/LoadingSpinner";

import contactImgDark from "../../images/contact-dark.svg";
import contactImgLight from "../../images/contact-light.svg";

import "./ContactMe.css";

const ContactMe = ({ darkMode }) => {
  const [showLoader, setShowLoader] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [msg, setMsg] = useState("");

  const serviceId = "service_ID";
  const templateId = "template_ID";
  const userId = "user_6cNss7LhhWXQiU7DLhGqK";

  const onSubmit = (data, r) => {
    setShowLoader(true);
    sendEmail(
      serviceId,
      templateId,
      {
        name: data.name,
        phone: data.phone,
        desc: data.desc,
      },
      userId
    );
    r.target.reset();
  };
  const sendEmail = (serviceId, templateId, variables, userId) => {
    emailjs
      .send(serviceId, templateId, variables, userId)
      .then(() => {
        setMsg("Thank you for cantacting!");
        swal({
          title: "Thank you for contacting!",
          text: "Hey, your message has been received. Thank You!🙂",
          icon: "success",
        });
        setShowLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setShowLoader(false);
      });
  };

  return (
    <div id="contact" className="contacts">
      <div className="text-center">
        <AttentionSeeker effect="heartBeat" duration="1500">
          <h1 className="title">contact me</h1>
        </AttentionSeeker>
        {msg === "" ? (
          <p className="m-2">
            Drop a suggestion, feedback or we can collaborate on a project.
            Please mention your contact details if you are expecting a reply.
          </p>
        ) : (
          <p className="text-success success__msg">
            <BiHappy />
            {msg}
          </p>
        )}
      </div>

      <div className="container">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-md-5 col-xs-12">
              <Zoom>
                {darkMode ? (
                  <img src={contactImgDark} className="contact__img" />
                ) : (
                  <img src={contactImgLight} className="contact__img" />
                )}
              </Zoom>
            </div>
            <div className="col-md-6 col-xs-12">
              <Slide duration="1000" direction="right">
                <div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Name"
                    name="name"
                    id="name"
                    {...register("name", {
                      required: true,
                      minLength: 3,
                      maxLength: 20,
                    })}
                  />
                  <div className="line"></div>
                  <span className="error-message">
                    <p className="text-danger">
                      {errors.name?.type === "required" && "Name is required!"}
                      {errors.name?.type === "minLength" &&
                        "Please enter name with minimum 3 characters!"}
                      {errors.name?.type === "maxLength" &&
                        "Please enter name with fewer than 20 characters!"}
                    </p>
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Mobile number (optional)"
                    name="phone"
                    id="phone"
                    {...register("phone", {
                      required: false,
                      minLength: 10,
                      maxLength: 10,
                    })}
                  />
                  <div className="line"></div>
                  <span className="error-message">
                    <p className="text-danger">
                      {(errors.phone?.type === "minLength" ||
                        errors.phone?.type === "maxLength") &&
                        "Phone number must be of 10 digits!"}
                    </p>
                  </span>
                  <textarea
                    type="text"
                    className="form-control"
                    placeholder="Message for me"
                    name="desc"
                    id="desc"
                    {...register("desc", {
                      required: true,
                      minLength: 10,
                    })}
                  ></textarea>
                  <div className="line"></div>
                  <p className="text-danger">
                    {errors.desc?.type === "required" && "Message is required!"}
                    {errors.desc?.type === "minLength" &&
                      "Your message should be greater than 10 characters atleast!"}
                  </p>
                  <div className="centered">
                    {showLoader ? (
                      <div className="centered">
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <button className="btn-grad btn-full" type="submit">
                        Send Message <FiSend />
                      </button>
                    )}
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactMe;

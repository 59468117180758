import React from "react";
import Marquee from "react-fast-marquee";
import { skills } from "../../data/Skills";
import { AttentionSeeker } from "react-awesome-reveal";

import "./Skills.css";

const Skills = () => {
  return (
    <div id="services" className="services">
      <AttentionSeeker effect="jello">
        <h1 className="py-5 title text-secondary">
          i can work <span className="with">with</span>
        </h1>
      </AttentionSeeker>
      <div>
        <Marquee
          speed="150"
          pauseOnClick={false}
          pauseOnHover={true}
          gradient={false}
        >
          {skills.map((skill) => (
            <div>
              <div className="box px-5">
                <h1 className="icon mb-3">{skill.icon}</h1>
                <h3>{skill.name}</h3>
              </div>
            </div>
          ))}
        </Marquee>
      </div>
    </div>
  );
};

export default Skills;

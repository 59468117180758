import { FaJava, FaDocker, FaAws, FaGitAlt } from "react-icons/fa";
import { GrMysql, GrNode, GrReactjs } from "react-icons/gr";
import { SiJavascript } from "react-icons/si";
import { SiSpring } from "react-icons/si";
import { DiMongodb, DiMaterializecss, DiCss3 } from "react-icons/di";
import { BsBootstrapFill } from "react-icons/bs";
import { AiFillHtml5 } from "react-icons/ai";

export const skills = [
  { name: "Java", icon: <FaJava /> },
  { name: "React.js", icon: <GrReactjs /> },
  { name: "Spring Boot", icon: <SiSpring /> },
  { name: "JavaScript", icon: <SiJavascript /> },
  { name: "Node.js", icon: <GrNode /> },
  { name: "MySQL", icon: <GrMysql /> },
  { name: "MongoDB", icon: <DiMongodb /> },
  { name: "Bootstrap", icon: <BsBootstrapFill /> },
  { name: "Materialize CSS", icon: <DiMaterializecss /> },
  { name: "Docker", icon: <FaDocker /> },
  { name: "AWS", icon: <FaAws /> },
  { name: "Git", icon: <FaGitAlt /> },
  { name: "HTML", icon: <AiFillHtml5 /> },
  { name: "CSS", icon: <DiCss3 /> },
];

export default {
  skills,
};

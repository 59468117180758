import React from "react";
import { AttentionSeeker } from "react-awesome-reveal";
import Typed from "react-typed";
import { Link } from "react-scroll";

import helloLightImg from "../../images/hello-light.svg";
import helloDarkImg from "../../images/hello-dark.svg";

import "./Header.css";

const Header = ({ darkMode }) => {
  return (
    <div id="home" className="header-wrapper">
      <div className="main-info">
        <AttentionSeeker headShake duration="1500">
          <h1>LOVE TO CODE{" </>"}</h1>

          <Typed
            className="typed-text m-3"
            strings={[
              "import { useMemo } from 'react'",
              "$ git commit -am",
              "private Object obj = new Object();",
              "$ npm install",
              "const app = require('express')",
              "$ docker run -it httpd",
              "server.port=8080",
            ]}
            typeSpeed={70}
            backSpeed={30}
            loop
          />

          {darkMode ? (
            <img src={helloDarkImg} alt="hello" className="header__hello-img" />
          ) : (
            <img
              src={helloLightImg}
              alt="hello"
              className="header__hello-img"
            />
          )}
        </AttentionSeeker>

        <Link
          offset={-50}
          smooth={false}
          to="contact"
          className="btn-grad"
          style={{ cursor: "pointer" }}
        >
          Contact Me
        </Link>
      </div>
    </div>
  );
};

export default Header;
